
import { Actions } from "../constants";

const taskReducer = (state, action) => {
    const { type, payload } = action;
    const { statName, value } = payload;
    console.log(`Message ${type.decription}: `, payload);
    switch(type) {
        case Actions.ADD_TO_INV:
            console.log(`Adding ${value.description} to ${statName.desccription}`);
            const v = (state[statName.description] ?? 0) + value;
            const newState = Object.assign({}, state, {
                [statName.description]: v
            });
            console.log(`Changed => ${statName.description}: ${newState[statName.description]}`, newState);
            return newState;
        
        default:
            console.log("Type", type.description);
            return state;
    }
}

export default taskReducer;