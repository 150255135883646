import React from 'react';
import { Grid } from 'semantic-ui-react';

const Stats = ({stats}) => {
    console.log("Stats: ", stats);
    const statItems = Object.keys(stats).map((key) => {
        return <Grid.Column key={key}>
            <span>{key}: {stats[key]}</span>
        </Grid.Column>
    });
    return (
        <Grid colums={5}>
            {statItems}
        </Grid>
    )
}

export default Stats