import {
        Tasks,
        Businesses,
        Currency
} from "./constants";

const initData = () => {
    const currency = Currency.map((name, index) => {
            return {
                id: index,
                name,
                value: 0
            }
        });
    const tasks = Tasks.map((task, index) => {
            return {
                id: index,
                ...task
            }
        });
    const businesses = Businesses.map((business, index) => {
            return {
                id: index,
                ...business
            }
        })
    return {
        Currency: currency,
        Tasks: tasks,
        Businesses: businesses
    }
}


export default initData();