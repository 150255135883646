const ONSTART = { statName: Symbol.for("Ferrets"), value: 0 }


// Actions
exports.Actions = { 
    ADD_TO_INV: Symbol.for("ADD_TO_INV")
}

// UI Text
// "Currency" - Things you get and "spend"
exports.Currency = [ Symbol.for("Money"), Symbol.for("Ferrets"), Symbol.for("Promises"), Symbol.for("Widgets") ];

const TASK_RECRUIT = Symbol.for("Recruit");
const TASK_PROMISE = Symbol.for("Promise");
const TASK_BUILD = Symbol.for("Build");
const TASK_COLLECT = Symbol.for("Collect");

// Task - Things you can do to modify stats, gain currency or gain levels
exports.Tasks = [
    {
        name: TASK_RECRUIT,
        description: "Recruit ferrets to your business",
        execute: {
            type: this.Actions.ADD_TO_INV,
            payload: { statName: Symbol.for("Ferrets"), value: 1 }
        }
    },
    {
        name: TASK_PROMISE,
        description: "Make promises for stuff we don't have yet.",
        execute: {
            type: this.Actions.ADD_TO_INV,
            payload: { statName: Symbol.for("Promises"), value: 1 }
        }
    },
    {
        name: TASK_BUILD,
        description: "Build a widget",
        execute: {
            type: this.Actions.ADD_TO_INV,
            payload: { statName: Symbol.for("Widgets"), value: 1 }
        }
    },
    {
        name: TASK_COLLECT,
        description: "Collect money",
        execute: {
            type: this.Actions.ADD_TO_INV,
            payload: { statName: Symbol.for("Money"), value: 1 }
        }
    }
]
// Containers - Things that are there to hold tasks
exports.Businesses = [
    {
        name: "Recruitment Department",
        description: "Recruit ferrets to your business",
        tasks: [
            TASK_RECRUIT
        ],
        requirements: [
            ONSTART
        ]
    },
    {
        name: "Sales Department",
        description: "Brings in the money.",
        tasks: [
            TASK_PROMISE
        ],
        requirements: [
            ONSTART
        ]
    },
    {
        name: "Engineering Department",
        description: "Fulfills the promises and builds the widgets.",
        tasks: [
            TASK_BUILD
        ],
        requirements: [
            {
                statName: Symbol.for("Promises"),
                value: 15
            }
        ]
    },
    {
        name: "Finance Department",
        description: "Collects the money.",
        tasks: [
            TASK_COLLECT
        ],
        requirements: [
            {
                statName: Symbol.for("Widgets"),
                value: 15
            }
        ]
    }
]

// Generators - Things that passively generate currency