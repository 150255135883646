// A Task is an action tied to a value

import React from 'react';
import { Button } from 'semantic-ui-react';


const Task = ({name, action, dispatch}) => {

    return (
        <Button onClick={() => {
            dispatch(action)
        }}>
            {name.description}
        </Button>
    )
}

export default Task