// A business is a collection of tasks

import React from 'react'
import { Card, Image} from 'semantic-ui-react'
import Task from './Task'
import SalesFerret from '../assets/SalesFerret.png';
import RecruitmentFerret from '../assets/RecruitmentFerret.png';
import EngineeringFerret from '../assets/EngineeringFerret.png';
import FinanceFerret from '../assets/FinanceFerret.png';

const Businesses = {
    SalesFerret,
    RecruitmentFerret,
    EngineeringFerret,
    FinanceFerret
}

const Business = ({name, description, tasks, active, dispatch}) => {
    if (active) {
        return (
            <Card>
                <Card.Header as='h2'>{name}</Card.Header>
                <Card.Content>
                    <Image src={Businesses[name.replace(' Department','') + 'Ferret']} />
                </Card.Content>
                <Card.Content extra>
                    {tasks.map(task => {
                        return <Task key={task.id} id={task.id} name={task.name} action={task.execute} dispatch={dispatch}></Task>
                    })}
                </Card.Content>
                <Card.Content extra>
                    {description}
                </Card.Content>
            </Card>
        )
    } else {
        return null;
    }
}

const BusinessContext = React.createContext();

export default Business;
export {BusinessContext};