import React from 'react';
import { Container, Image } from 'semantic-ui-react';
import bannerImg from '../../public/img/ferret-import-banner.png'

const Banner = () => {
    return (
        <Container fluid>
            <Image src={bannerImg} alt="banner" />
        </Container>
    )
}

export default Banner;