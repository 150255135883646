import React from 'react';
import ReactDOM from 'react-dom';
import App from './src/App';
import 'semantic-ui-css/semantic.min.css';
import Home from './src/components/Home';
import Banner from './src/components/Banner';
import Layout from './src/components/Layout';
import { Container } from 'semantic-ui-react';
import background from './public/img/albino.jpg';

const searchParams = new URLSearchParams(window.document.location.search);

if (searchParams.has('test')) {
    ReactDOM.render(
    <Container>
        <Layout onMobile={
            <React.Fragment>
                <Banner mobile />
                <App mobile />
            </React.Fragment>
        } onDesktop={
            <React.Fragment>
                <Banner />
                <App />
            </React.Fragment>
        }/>
    </Container>, document.getElementById('root'))
} else {
    ReactDOM.render(
    <Container>
        <Layout onMobile={
            <Home mobile />
        } onDesktop= {
            <Home desktop />
        }>
        </Layout>
    </Container>
    ,document.getElementById("root"))
}