/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import { Container, Header } from 'semantic-ui-react'

const Home = ({mobile}) => {
  return (
    <Container text style={
      {
        minHeight: mobile ? '15%' : '35%',
        padding: mobile ? '1em 2em' : '0px 1em',
        maxHeight: '300px',
        background: '#000000'
      }
    }>
      <Header
        as='h1'
        content='Ferrets.biz'
        inverted
        style={{
          fontSize: mobile ? '2em' : '4em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: mobile ? '1.5em' : '3em',
        }}
      />
      <Header
        as='h2'
        content='The collective noun for ferrets is a business.'
        inverted
        style={{
          fontSize: mobile ? '1.5em' : '1.7em',
          fontWeight: 'normal',
          marginTop: mobile ? '0.5em' : '1.5em',
        }}
      />
      <Header
        as='h3'
        content='Stay Tuned...'
        inverted
        style={{
          textAlign: 'right',
          fontSize: mobile ? '1.5em' : '1.7em',
          fontWeight: 'normal',
          marginTop: mobile ? '0.5em' : '1.5em',
        }}
      />
    </Container>)
}

export default Home;