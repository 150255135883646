import React, { useReducer } from 'react'
import { Card, Container } from 'semantic-ui-react'
import Business from './components/Business'
import Stats from './components/Stats'
import { taskReducer } from './reducers';

import data from './datashape.js'

const startingState = {"Ferrets": 3};

const App = ()=> {
    const [state, dispatch] = useReducer(taskReducer, startingState);

    const Businesses = data.Businesses;
    const Tasks = data.Tasks;

    const meetsRequirements = (business) => {
        const requirements = business.requirements || [];
        const met = requirements.every(req => {
            const item = Symbol.keyFor(req.statName);
            return (state[item] ?? 0) >= req.value;
        })
        return met;
    }

    return <Container>
        <Stats stats={state} />
        <Card.Group>
            {Businesses.map(business => {
                const tasks = Tasks.filter(task => business.tasks.includes(task.name));
                const active = meetsRequirements(business);
                return <Business key={business.id} active={active} {...business} tasks={tasks} dispatch={dispatch} />
            })}
        </Card.Group>
    </Container>
}

export default App;