import React from 'react'
import { createMedia } from "@artsy/fresnel";


const MobileLayout = ({ children }) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
};


const DesktopLayout = ({ children }) => {
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
};

const Layout = ({ onMobile, onDesktop }) => {
    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
        mobile: 0,
        tablet: 768,
        desktop: 1024,
        },
    })

    return (
        <MediaContextProvider>
            <Media lessThan="desktop">
                <MobileLayout>{onMobile}</MobileLayout>
            </Media>
            <Media greaterThanOrEqual="desktop">
                <DesktopLayout>{onDesktop}</DesktopLayout>
            </Media>
        </MediaContextProvider>
    )
};

export default Layout;
export { Layout as Layout, DesktopLayout, MobileLayout };